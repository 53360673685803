// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bP_kw";
export var caseStudyBackground__lineColor = "bP_kt";
export var caseStudyHead = "bP_kn";
export var caseStudyHead__imageWrapper = "bP_kp";
export var caseStudyProjectsSection = "bP_kx";
export var caseStudyQuote__bgRing = "bP_kr";
export var caseStudyVideo__ring = "bP_kG";
export var caseStudy__bgDark = "bP_km";
export var caseStudy__bgDarkReverse = "bP_mB";
export var caseStudy__bgLight = "bP_kl";