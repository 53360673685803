import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Dan.module.scss'
import useDanStaticQuery from './useDanStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

export const quotes = {
  ceo: {
    name: 'Dmytro',
    position: 'CEO & Founder at Codica',
  },
  pm: {
    name: 'Andrii',
    position: 'Project Manager at Codica',
  },
  be: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewCakerTitle = 'Custom SaaS platform for CakerHQ'
const previewMalleniTitle = (
  <>
    Custom <br /> E-commerce Solution
  </>
)

const CaseStudiesDan = () => {
  const query = useDanStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Custom marketplace for trading domain names"
          subtitle="Learn how the Codica team helped the customer to redesign old functionality and develop new significant features for the Dan platform for selling domain names."
          maxWidthSubtitle="380px"
          maxWidthInfo="373px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Custom marketplace for trading domain names"
          imageTitle="Custom marketplace for trading domain names"
          domain="Online domain trading"
          location="Netherlands"
          timeline="September 2021 - Ongoing"
          services={
            <>
              Front-end, Back-end development,
              <Link
                to="/services/online-marketplace-development/"
                className="ml5"
                key="marketplaceKey"
              >
                Online marketplace development
              </Link>
            </>
          }
          team="3 Front-end Developers, 4 Back-end Developers, 2 QA Engineers, 1 Project Manager, 1 Back-end Teach Lead, 1 Front-end Teach Lead"
          technologies="Ruby, Ruby on Rails, React, Typescript, PostgreSQL, AWS, Redis, Sidekiq, Adyen, Docker, Docker Compose"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“At Codica, Dan.com added to our portfolio of successful projects. Thanks to our help, this domain platform is at a new level in the market.',
            },
            {
              text: 'This domain website is famous for the convenient functionality our team has guaranteed. It is about new features, options, filters, pop-ups, categories, columns, toolbar, a price calculator, infinite scroll, and much more. All these new elements ensure fast, seamless, and convenient use of the platform.',
            },
            {
              text: 'The Codica team is always eager to cooperate technically with similar projects and their founders. Moreover, it is important to us to guarantee business owners the high quality of their products.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/CEO.png"
              alt={`${quotes.ceo.name}, ${quotes.ceo.position} at Codica`}
              title={`${quotes.ceo.name}, ${quotes.ceo.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.ceo.name}
          position={quotes.ceo.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDarkReverse}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={
          <>
            Dan.com is a user-friendly and trustworthy domain sale website. It
            is a platform where users can buy or sell already registered domains
            quickly and efficiently.
            <div className="mb-3" key="bgTextLeft" />
            The
            <Link
              to="/services/multi-vendor-marketplace-development/"
              className="mx5"
              key="keyBgMVendor"
            >
              multi-vendor marketplace development
            </Link>
            platform has mainly 3 roles. Buyers can only purchase domains and
            manage payments using the Buyer control panel page. Sellers can
            import their domains and manage everything related to them - prices,
            buying options, or conversations with buyers.
          </>
        }
        bgTextRight={[
          'Admins allow buyers and sellers to buy or sell the domain names, like processing the requests/questions from all users, domain transferring, transactions, etc.',
          <div className="mb-3" key="bgTextRight" />,
          'With the old technology stack, it was challenging to integrate new features and redesign the UI part of the Portfolio in the way the client planned, so we built the new Portfolio from scratch. The client chose our Codica team since we are specialists in the required technologies, and we can develop a product of any complexity using them.',
        ]}
        sectionBusiness={[
          {
            label:
              'Make editing the domains in the Portfolio table more comfortable without wasting time. Also, make it possible to renew many domains with just one click.',
          },
          {
            label:
              'Process vast data simultaneously and optimize search, filters, and sorting mechanisms. Moreover, implement an infinite scroll that allows operating with thousand domains on one page instead of complex pagination.',
          },
          {
            label:
              'Create a pixel-perfect UI of the Portfolio page and improve the whole navigation bar of the platform for a better user experience.',
          },
          {
            label:
              'Test the functionality very thoroughly. The Portfolio is the website’s core that is connected with all other valuable parts of the online marketplace platform. To totally avoid releasing complex functionality with critical bugs for 4 million active users per month.',
          },
          {
            label:
              'Adapt the aftermarket domain names platform for all screens of devices. Make all desktop functions available on mobile/tablet versions.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Implemented a valuable toolbar for bulk changes. Added the ability to edit domain options, which were previously not available in the portfolio. Now, there is no need to go to another page to edit parameters.',
          },
          {
            label:
              'Significantly improved overall platform optimization. Optimized search algorithm and made it possible to combine your existing search query with the filter options and sorting. Moreover, customized a quick infinite scroll instead of endless pagination. Also, accelerated bulk changes, import, and export of domains.',
          },
          {
            label:
              'Provided UI redesign of all components on the page. Redesigned the navigation bar and made it expandable to take up less space on the domains marketplace.',
          },
          {
            label:
              'Completely revamped the mobile designs to provide the best user experience. It is now easier to manage your domain portfolio directly from your mobile phone or tablet.',
          },
          {
            label:
              'Added a feature to create domain categories and then share them on the public page of the seller. Buyers can filter by this category the seller’s domains.',
          },
        ]}
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“Our goal was to update the existing portfolio management feature without letting down the thousands of users who were used to the old version. We used Ruby on Rails and React as a new tech stack. It was challenging but was done smoothly.',
          },
          {
            text: 'We managed to get the job done and got many positive reactions on Twitter. We’re proud of our work and the results. It was a great example of how we can improve an existing product in a way that is helpful to the users. We made sure the new version didn’t disrupt the user experience.',
          },
          {
            text: 'We look forward to taking on more projects like this in the future.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/Sergey.png"
            alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
            title={`${quotes.be.name}, ${quotes.be.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.be.name}
        position={quotes.be.position}
      />

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDarkReverse}
        title="Do you need a reliable team to build a custom marketplace platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          isImageSmall
          image={solutionDeliveredOne}
          title="Best UX with revamped mobile designs"
          description={[
            'You can now easily manage your portfolio of an online marketplace for domains trading directly from your mobile phone or tablet etc.',
            <div className="mb-2" key="descr" />,
            'Therefore, mobile and tablet versions have the same functionality as desktop ones. You can use the settings and toolbar functionality. For example, you can add filters and categories or export selected domains.',
          ]}
          alt="Mobile design for selling domain platform"
          imageTitle="Mobile version for selling domain platform"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Portfolio UI upgrade"
          description={
            <>
              With our help, the platform for selling domain names started
              working much faster and became more convenient. Compared with the
              previous design, the left navigation panel has expanded and offers
              more dropdowns now.
              <div className="mb-2" key="descr" />
              After scrolling in this sector, you can choose the filter for
              domains - rented, sold, deleted. Also, you can immediately select
              or create a category of domains to direct the search to a specific
              niche more efficiently.
              <div className="mb-2" key="descrTwo" />
              Moreover, the table, header, footer, all models, and popups have
              also received
              <Link
                to="/services/ui-ux-design/website-redesign-services/"
                style={{ color: '#e4282f', textDecoration: 'underline' }}
                className="ml5"
                key="keyCaseSolutionRedesignKey"
              >
                UI redesign
              </Link>
              . Now all these elements have acquired a more attractive
              appearance and convenient use.
            </>
          }
          alt="Redesigned left navigation panel for selling domain platform"
          imageTitle="Improved left navigation panel for selling domain platform"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Custom domain categories"
          description={[
            'Domain categories have appeared in the left navigation panel of the online portal for selling domain names.',
            <div className="mb-2" key="descr" />,
            'You can choose existing domain categories or create your custom domain category. In addition, it is worth mentioning another interesting feature our team added - Sharing custom domain categories.',
          ]}
          alt="Custom domain categories for selling domain platform"
          imageTitle="Domain categories for selling domain platform"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Added toolbar for bulk changes"
          description={[
            'The toolbar for bulk changes deserves special attention. By selecting specific domains in the checkbox, a panel will appear at the bottom of the domain names marketplace. You can find Pricing, Sales page, Distribute, Ads, and Delete sections among the existing bulk actions.',
            <div className="mb-2" key="descr" />,
            'So, we facilitate the ability to edit all pricing options and public domain Sales page simultaneously in one pop-up for thousands of domains. This also applies to enabling and disabling Ads.',
            <div className="mb-2" key="descrTwo" />,
            'Moreover, with the calculator in the Pricing section, you can easily calculate the amount of the domain if you want to add or subtract specific parameters.',
          ]}
          alt="Enhanced checkbox for selling domain platform"
          imageTitle="Redesigned checkbox for selling domain platform"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Enhanced table functionality"
          description={[
            'Our team has added a "Domain extension" column to the main table so that users can easily choose credible and trustworthy domain extensions. Also, "Template" column appeared in the sale section of the domain sale website. It is about default, standard, brandable, and minimalistic domain layouts.',
            <div className="mb-2" key="descr" />,
            'To make users of the domains marketplace as happy as possible, our specialists have added new domain filters and options. Therefore, with our updates, you can edit new fields in the table and sort and hide columns. Most interestingly, we added a convenient infinite scroll instead of complex pagination of domains.',
          ]}
          alt="Improved table design for selling domain platform"
          imageTitle="Enhanced table functionality for selling domain platform"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The video below shows you how this domain names marketplace works. We made several crucial changes to redesign the Portfolio from the old view to the new one."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/ly8J6pLmqXA"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDarkReverse}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby', icon: 'ruby' },
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'React', icon: 'react' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Typescript', icon: 'typescript' },
            { label: 'TanStack Query & Table', icon: 'tanStackQueryTable' },
            { label: 'Zustand', icon: 'zustand' },
            { label: 'React Hook Form', icon: 'reactHookForm' },
          ]}
          integrations={[
            { label: 'Adyen', icon: 'adyen' },
            { label: 'Docker', icon: 'docker' },
            { label: 'Docker Compose', icon: 'dockerCompose' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“I was delighted to work on the Portfolio redesign project with our team! We managed to address major customers’ pains. So, we made their domain management experience much more convenient by implementing new valuable features and optimizing the whole Portfolio. Moreover, the implemented responsive design enabled users to manage their domains in Portfolio on any device easily!',
          },
          {
            text: 'The cohesiveness of our team and structured collaboration with the client made the initially designed idea real in several months. Consequently, our result was highly appreciated by millions of real users.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/PM-Andrii.png"
            alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
            title={`${quotes.pm.name}, ${quotes.pm.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.pm.name}
        position={quotes.pm.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewCakerTitle}
              color="cakerColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/caker-hq.png"
                  alt={`Case study: ${previewCakerTitle} | Codica`}
                  title={`Case study: ${previewCakerTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="saas-platform-for-bakery-business"
            />
            <CaseStudyPreview
              title={previewMalleniTitle}
              color="malleniColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/malleni.png"
                  alt={`Case study: ${previewMalleniTitle} | Codica`}
                  title={`Case study: ${previewMalleniTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="custom-multi-vendor-marketplace-platform"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesDan
